@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700;800&display=swap");
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input {
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
input[type="text"],
input[type="button"],
input[type="submit"] {
  background-color: none;
}
